import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createPolicy,
  quotes
  // zipcodevalidation
} from '../../../constants/apiconstants'
import produce from 'immer'
import { revertAll } from '@pages/feature/common/commonAction'

// state.pageStatus = produce(state.pageStatus, (draftState) => {
//   draftState[payload.index] = payload.status;
// });

// create a slice function with name, initial State and reducer function.

const authState = {
  safeRiderData: '',
  loading: false,
  error: '',
  UpdatedData: '',
  exposurePerils: false
}

export const saferiderSlice = createSlice({
  name: 'saferider',
  initialState: authState,
  reducers: {
    onSubmitSafeRider: (state, { payload }) => ({
      ...state,
      safeRiderData: payload
    }),
    setExposurePerilsFlag: (state, { payload }) => ({
      ...state,
      exposurePerils: payload
    }),
    reset: (state, { payload }) => ({
      ...state,
      safeRiderData: '',
      loading: false,
      error: '',
      data: ''
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(safeRiderUpdate.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(safeRiderUpdate.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(safeRiderUpdate.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.UpdatedData = action.payload
    })
  }
})

export const addPerils = createAsyncThunk(
  'addPerils',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({
        payload: params.body,
        locator: params.quotelocator
      }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const safeRiderUpdate = createAsyncThunk(
  'updateSafeRiderApi',
  async (params, thunkAPI) => {
    let result = await fetch(Url + quotes + '/' + params.locator, {
      method: 'PATCH',
      body: JSON.stringify(params.body),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data.characteristics.fieldValues.safe_rider_program[0])
      .then((error) => error)

    return result
  }
)

export const { onSubmitSafeRider, setExposurePerilsFlag } =
  saferiderSlice.actions

export const saferiderReducer = saferiderSlice.reducer
