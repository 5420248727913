import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createPolicy,
  quote,
  quotes,
  selectQuote,
  endorsements,
  policies
} from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
const authState = {
  addressResponse: '',
  exposuresLength: 0,
  recentExposureLocator: '',
  vehicleCount: 0,
  vehiclePage: 0,
  postDataVehiclePage1: '',
  postDataVehiclePage2: '',
  addVehicleArray: [],
  loading: false,
  error: '',
  vehicleExposures: [],
  vehicleData: '',
  vehicleList: [],
  endorsedVehicleList: [],
  endorsementLocator: '',
  endorsedVehciles: [],
  displayAllVehcile: [],
  vehicleEndorsementStatus: false,
  endorsementDate: '',
  removedEndorsementVehicle: []
}

export const vehicledetailsSlice = createSlice({
  name: 'vehicledetails',
  initialState: authState,
  reducers: {
    setExposuresLength: (state, { payload }) => ({
      ...state,
      exposuresLength: payload
    }),
    setEndorsedVehicleList: (state, { payload }) => ({
      ...state,
      endorsedVehicleList: payload
    }),
    setVehicleList: (state, { payload }) => ({
      ...state,
      vehicleList: payload
    }),
    setEndorsementDate: (state, { payload }) => ({
      ...state,
      endorsementDate: payload
    }),
    setVehicleEndorsementStatus: (state, { payload }) => ({
      ...state,
      vehicleEndorsementStatus: payload
    }),
    setRemovedVehicle: (state, { payload }) => ({
      ...state,
      removedEndorsementVehicle: payload
    }),
    setEndorsedVehicle: (state, { payload }) => ({
      ...state,
      endorsedVehciles: payload
    }),
    setEndorsementLocator: (state, { payload }) => ({
      ...state,
      endorsementLocator: payload
    }),
    setDisplayAllVehicle: (state, { payload }) => ({
      ...state,
      displayAllVehcile: payload
    }),

    setRecentExposureLocator: (state, { payload }) => ({
      ...state,
      recentExposureLocator: payload
    }),
    setVehicleCount: (state, { payload }) => ({
      ...state,
      vehicleCount: payload
    }),
    setVehicleData: (state, { payload }) => ({
      ...state,
      vehicleData: payload
    }),
    setVehiclePage: (state, { payload }) => ({
      ...state,
      vehiclePage: payload
    }),
    setPayloadPage1: (state, { payload }) => ({
      ...state,
      postDataVehiclePage1: payload
    }),
    setAddVehicleArray: (state, { payload }) => ({
      ...state,
      addVehicleArray: payload
    }),
    setPayloadPage2: (state, { payload }) => ({
      ...state,
      postDataVehiclePage2: payload
    }),
    reset: (state, { payload }) => ({
      ...state,
      addressResponse: '',
      savedData: '',
      loading: false,
      error: '',
      vehicleExposures: ''
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(addressValidation.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(addressValidation.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(addressValidation.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.addressResponse = action.payload
    })

    builder.addCase(vehicleUpdateQuoteLevel.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(vehicleUpdateQuoteLevel.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(vehicleUpdateQuoteLevel.fulfilled, (state, action) => {
      state.loading = 'Complete'
      if (!(parseInt(action.payload.status) >= 400 || action.payload.message)) {
        state.vehicleExposures = action.payload.exposures
      }
    })

    builder.addCase(vehicleFetchQuoteLevel.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(vehicleFetchQuoteLevel.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(vehicleFetchQuoteLevel.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(vehicleCreateEndorsement.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(vehicleCreateEndorsement.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(vehicleCreateEndorsement.fulfilled, (state, action) => {
      state.loading = 'Complete'
      if (!(parseInt(action.payload.status) >= 400 || action.payload.message)) {
        state.endorsementLocator = action.payload.locator
      }
    })
    builder.addCase(vehicleUpdateEndorsement.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(vehicleUpdateEndorsement.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(vehicleUpdateEndorsement.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.vehicleData = action.payload.addExposures
    })
  }
})

export const vehicleUpdateQuoteLevel = createAsyncThunk(
  'vehicleUpdateQuoteApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator, payload: params.body }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)

export const vehicleFetchQuoteLevel = createAsyncThunk(
  'vehicleFetchQuoteApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/fetchquote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)

export const vehicleCreateEndorsement = createAsyncThunk(
  'vehicleCreateEndorsementApi',
  async (params, thunkAPI) => {
    let result = await fetch(
      '/api/usercontroller/endorsement/createendorsement',
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          // Authorization: params.auth?.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)
export const vehicleUpdateEndorsement = createAsyncThunk(
  'vehicleUpdateEndorsementApi',
  async (params, thunkAPI) => {
    let result = await fetch(Url + 'endorsements' + '/' + params.locator, {
      method: 'PATCH',
      body: JSON.stringify(params.body),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)

export const addressValidation = createAsyncThunk(
  'addressValidationApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/address/checkinfo', {
      method: 'POST',
      body: JSON.stringify(params.payload),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => {
        console.error(error);
        return error;
      })
    return valid
  }
)

export const {
  setExposuresLength,
  setRecentExposureLocator,
  setVehicleCount,
  setPayloadPage1,
  setPayloadPage2,
  setVehiclePage,
  setAddVehicleArray,
  setVehicleData,
  setEndorsementLocator,
  setVehicleEndorsementStatus,
  setEndorsedVehicle,
  setDisplayAllVehicle,
  setEndorsementDate,
  setRemovedVehicle,
  setVehicleList,
  setEndorsedVehicleList
} = vehicledetailsSlice.actions

export const vehicledetailsReducer = vehicledetailsSlice.reducer
