import { Flex } from '../layout'
import { useEffect, useRef, useState } from 'react'
import { ROUTE_PATHS, IMAGE_PATHS } from '../../constants'
import Popover from '@mui/material/Popover'
import { Button } from '@components/forms'
import { Modal } from '@components/modal'
import '../../node_modules/primereact/resources/primereact.min.css'
import { IdleTimerContainer } from '@components/idealtimer'
import '../../node_modules/primeflex/primeflex.css' //icons
import { signOut, useSession } from 'next-auth/react'
import { useSelector, useDispatch } from 'react-redux'
import { authSocotra } from '../../pages/feature/common/auth/authSocotraSlice'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useRouter } from 'next/router'
import { BsFillCaretDownFill } from 'react-icons/bs'
import TagManager from 'react-gtm-module'

const commonLinks = [{ href: '/', text: 'Logo' }]

export function Navbar() {
  const dispatch = useDispatch()
  const { data: session, status } = useSession()
  const loading = status === 'loading'
  const dashboardState = useSelector((state) => state.dashboard)
  const finalQuoteState = useSelector((state) => state.finalQuotes)
  const navbarRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [close, setClose] = useState(false)
  const router = useRouter()
  const [reloadModal, setReloadModal] = useState(false)

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TSNF8Q9' })
  }, [])

  useEffect(() => {
    const timerId = setInterval(async () => {
      dispatch(authSocotra())
    }, 1000 * 1740)
    return () => {
      clearInterval(timerId)
    }
  }, [])

  useEffect(() => {
    let initializedState = localStorage.getItem('appInitializationFlag')
    if (
      typeof window !== 'undefined' &&
      initializedState === 'true' &&
      !(
        window.location.pathname == '/' ||
        window.location.pathname == '/dashboard' ||
        window.location.pathname == '/login' ||
        window.location.pathname.includes('/docsign') ||
        window.location.pathname.includes('/r/') ||
        window.location.pathname.includes('/lead-quote')
      )
    ) {
      window.addEventListener('beforeunload', setReloadModal(true))
      return () => {
        window.removeEventListener('beforeunload', setReloadModal(true))
      }
    }
    let purchasePath = window.location.pathname
    if (
      typeof window !== 'undefined' &&
      (window.location.pathname == '/' ||
        window.location.pathname == '/dashboard' ||
        window.location.pathname == '/login' ||
        window.location.pathname.includes('/docsign') ||
        purchasePath.includes('/finalapplication/purchase/')) &&
      (localStorage.getItem('appInitializationFlag') == null ||
        localStorage.getItem('appInitializationFlag') == undefined)
    ) {
      localStorage.setItem('appInitializationFlag', 'true')
    }
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setClose(true)
  }

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const alertUser = () => {
    localStorage.clear()
    sessionStorage.clear()
    setReloadModal(false)
    localStorage.setItem('appInitializationFlag', 'false')
    router.push(ROUTE_PATHS.HOME)
  }

  const closeAlertUserModal = () => {
    setReloadModal(false)
  }

  const logoutApp = (e: any) => {
    if (e) e.preventDefault()
    signOut()
  }

  return (
    <>
      <div className="bg-white" style={{ minHeight: '64px' }}>
        <nav ref={navbarRef} className="nav">
          <div className="mx-2 sm:mx-5 max-w-8xl ">
            <Modal
              showsModal={reloadModal}
              description={
                <div className="flex flex-column justify-content-center text-center mb-3">
                  <div>
                    <p className="text-4xl headline-blue font-Oswald mb-2 font-bold">
                      Refreshing the page removes the saved data. Do you wish to
                      continue?
                    </p>
                  </div>
                </div>
              }
              body={
                <>
                  <div className="flex flex-row column-gap-3 justify-content-center">
                    <Button
                      type="button"
                      onClick={closeAlertUserModal}
                      className="my-2 h-10 w-auto font-bold py-3 sm:py-2 px-4 text-black border-2 border-orange-600 bg-white">
                      <span className="font-color-asphalt-gray">CANCEL</span>
                    </Button>
                    <Button
                      type="button"
                      onClick={alertUser}
                      className="my-2 h-10 w-auto font-bold py-2 px-3 text-white  button-orange-bg">
                      <div className="font-semibold text-sm text-white ">
                        OK
                      </div>
                    </Button>
                  </div>
                </>
              }
            />

            <Flex className="relative justify-between p-2 h-16">
              <div className="sm:mt-0 mt-3  w-9rem sm:w-12rem   items-center justify-center font-Sans font-semibold   sm:ml-2 sm:flex sm:space-x-8">
                <img
                  onClick={() => {
                    router.push(ROUTE_PATHS.BOUNDLESS_HOME_PAGE)
                  }}
                  src={IMAGE_PATHS.BOUND_LOGO}
                  className="cursor-pointer"
                />
              </div>

              <Flex className="flex-row flex-wrap items-center  sm:w-auto sm:pr-4">
                {typeof window !== 'undefined' && (
                  <div className="hidden sm:flex flex-column">
                    <div className="qstn sm:block hidden  align-items-center justify-content-center  font-Sans font-semibold  text-900">
                      <p className="font-Sans text-base">Have a question?</p>
                    </div>
                    <div className="call sm:block hidden align-items-center justify-content-center font-Sans font-semibold  text-900 text-sm ">
                      <p className="font-Sans">Call (800)-814-7231</p>
                    </div>
                  </div>
                )}
                {typeof window !== 'undefined' &&
                  dashboardState?.pageError === 'false' &&
                  (window.location.pathname == '/dashboard' ||
                    window.location.pathname == '/viewpolicy' ||
                    window.location.pathname == '/viewprofile' ||
                    window.location.pathname == '/claims/allclaims' ||
                    window.location.pathname == '/claims/viewclaims') && (
                    <div>
                      <div className="ml-3" onClick={handleClick}>
                        <div className="flex flex-row column-gap-1 cursor-pointer">
                          <p className="text-white text-lg">My Account</p>
                          <BsFillCaretDownFill className="text-white h-8" />
                        </div>
                      </div>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose || close}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        className="mt-2">
                        <Stack
                          direction="column"
                          spacing={2}
                          className="w-12rem p-3">
                          <div
                            onClick={() => {
                              router.push(ROUTE_PATHS.VIEW_PROFILE)
                              handleClose()
                            }}>
                            <p className="cursor-pointer">View Profile</p>
                          </div>
                          <Divider className="" />
                          <div
                            onClick={() => {
                              router.push(ROUTE_PATHS.VIEW_POLICY)
                              handleClose()
                            }}>
                            <p className="cursor-pointer">View Policy</p>
                          </div>
                          <Divider className="" />
                          <div
                            onClick={(e) => {
                              e.preventDefault()
                              handleClose()
                              window.location.href = 'tel:18008147231'
                            }}
                            className="sm:hidden cursor-pointer hover:bg-gray-100 transition-colors duration-200">
                            <p className="flex items-center">Contact Us</p>
                          </div>
                          <Divider className="flex sm:hidden" />

                          {/* <div
                            onClick={() => {
                              router.push(ROUTE_PATHS.ALL_CLAIMS)
                              handleClose()
                            }}>
                            <p className="cursor-pointer">Claims</p>
                          </div> */}
                          <div
                            onClick={() => {
                              signOut()
                              handleClose()
                              alertUser()
                              router.push(ROUTE_PATHS.LOGIN)
                            }}>
                            <p className="cursor-pointer">Log out</p>
                          </div>
                        </Stack>
                      </Popover>
                    </div>
                  )}

                {window.location.pathname == '/dashboard' ||
                window.location.pathname == '/viewpolicy' ||
                window.location.pathname == '/viewprofile' ? null : (
                  <a
                    href="tel:18008147231"
                    className="flex sm:hidden flex-row flex-nowrap pr-1 sm:pr-4">
                    <div className="">
                      <i className="pi pi-phone border-1 border-circle p-2 bg-white border-gray-600" />
                    </div>
                  </a>
                )}
                {session?.user && (
                  <IdleTimerContainer
                    logout={logoutApp}
                    isAuthenticated={session?.user}
                  />
                )}
              </Flex>
            </Flex>
          </div>
        </nav>
      </div>
    </>
  )
}
