import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authUrl } from '../../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'

const authState = {
  isLoading: false,
  isError: '',
  env: '',
  authData: {},
  accessToken: ''
}

export const authSocotraSlice = createSlice({
  name: 'authSocotra',
  initialState: authState,
  reducers: {
    setEnv: (state, { payload }) => {
      state.env = payload
    },
    setToken: (state, { payload }) => {
      state.authData = payload
    },
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload
    }
  },

  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(authSocotra.pending, (state, action) => ({
      ...state,
      isLoading: action.payload
    }))
    builder.addCase(authSocotra.rejected, (state, action) => ({
      ...state,
      isLoading: 'rejected',
      isError: action.error.message
    }))

    builder.addCase(authSocotra.fulfilled, (state, action) => ({
      ...state,
      isLoading: 'complete',
      authData: action.payload
    }))
  }
})

export const authSocotra = createAsyncThunk(
  'authApi',
  async (params, ThunkAPI) => {
    let auth = await fetch(params.Url + authUrl, params.header).then(
      (response) => response.json()
    )
    return auth
  }
)

export const { setAuthData, setEnv, setToken, setAccessToken } =
  authSocotraSlice.actions
export const authSocotraReducer = authSocotraSlice.reducer
