import { createSlice } from '@reduxjs/toolkit'

const authState = {
  basicPerils: {},
  advancedPerilsATVUTV: {},
  advancedPerilsDirtBike: {},
  advancedPerilsEBike: {},
  advancedPerilsMotorcycle: {},
  advancedPerilsSnowmobile: {},
  premiumATVUTVPerilsORV: {},
  premiumDBPerilsORV: {},
  premiumPerils: {},
  premiumPerilsORV: {},
  premiumSnowmobilePerilsORV: {},
  customPerilsEbike: {},
  customPerilsMotorcycle: {},
  customPerils_ORV: {},
  perilsEbike: {},
}

export const perilsdataSlice = createSlice({
  name: 'perilsdata',
  initialState: authState,
  reducers: {
    setBasicPerils: (state, { payload }) => {
      state.basicPerils = payload
    },
    setAdvancedPerilsATVUTV: (state, { payload }) => {
      state.advancedPerilsATVUTV = payload
    },
    setAdvancedPerilsDirtBike: (state, { payload }) => {
      state.advancedPerilsDirtBike = payload
    },
    setAdvancedPerilsEBike: (state, { payload }) => {
      state.advancedPerilsEBike = payload
    },
    setAdvancedPerilsMotorcycle: (state, { payload }) => {
      state.advancedPerilsMotorcycle = payload
    },
    setAdvancedPerilsSnowmobile: (state, { payload }) => {
      state.advancedPerilsSnowmobile = payload
    },
    setPremiumATVUTVPerilsORV: (state, { payload }) => {
      state.premiumATVUTVPerilsORV = payload
    },
    setPremiumDBPerilsORV: (state, { payload }) => {
      state.premiumDBPerilsORV = payload
    },
    setPremiumPerils: (state, { payload }) => {
      state.premiumPerils = payload
    },
    setPremiumPerilsORV: (state, { payload }) => {
      state.premiumPerilsORV = payload
    },
    setPremiumSnowmobilePerilsORV: (state, { payload }) => {
      state.premiumSnowmobilePerilsORV = payload
    },
    setCustomPerilsEbike: (state, { payload }) => {
      state.customPerilsEbike = payload
    },
    setCustomPerilsMotorcycle: (state, { payload }) => {
      state.customPerilsMotorcycle = payload
    },
    setCustomPerils_ORV: (state, { payload }) => {
      state.customPerils_ORV = payload
    },
    setPerilsEbike: (state, { payload }) => {
      state.perilsEbike = payload
    },
  },
  extraReducers: (builder) => {

  }
})

export const {
  setBasicPerils,
  setAdvancedPerilsATVUTV,
  setAdvancedPerilsDirtBike,
  setAdvancedPerilsEBike,
  setAdvancedPerilsMotorcycle,
  setAdvancedPerilsSnowmobile,
  setPremiumATVUTVPerilsORV,
  setPremiumDBPerilsORV,
  setPremiumPerils,
  setPremiumPerilsORV,
  setPremiumSnowmobilePerilsORV,
  setCustomPerilsEbike,
  setCustomPerilsMotorcycle,
  setCustomPerils_ORV,
  setPerilsEbike
} = perilsdataSlice.actions

export const perilsdataReducer = perilsdataSlice.reducer
