import moment from 'moment-timezone'
import momenttz from 'moment-timezone'


export const coverageDateIsValid = (coverageDateString) => {

  let effectiveTimestamp = midnightTimestampFromDateString(coverageDateString, 0)

  let todayDateString = momenttz.tz('America/New_York').format('YYYY-MM-DD')

  let todayTimestamp = midnightTimestampFromDateString(todayDateString, 0)

  return (effectiveTimestamp >= todayTimestamp) 
}

const midnightTimestampFromDateString = (dateString) => {

  const possibleFormats = ['DD/MM/YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY']

  let c_Date = moment(`${dateString} 00:01:00`).tz('America/New_York').format(
    'YYYY-MM-DD HH:mm:ss'
  )
  const timestamp =  moment(`${dateString} 00:01:00`).tz('America/New_York').valueOf(); // '2014-08-18T01:00:00+01:00'
  return timestamp
}

export const estTimeExtract = (coverageDate) => {
  // set start coverage to 1 minute after midnight of the coverage date to keep quoted premium values consistent
  // the issued date and document sign dates will be used to validate time of date coverage in the case of a claim
  let c_Date = moment(`${coverageDate} 00:01:00`).format( 'YYYY-MM-DD HH:mm:ss')
  let startCoverageTimestamp = momenttz.tz(c_Date, 'America/New_York').valueOf()
  let f_Date = moment(c_Date).add(1, 'Y').format('YYYY-MM-DD HH:mm:ss')
  let endCoverageTimestamp = momenttz.tz(f_Date, 'America/New_York').valueOf()

  return {
    from: startCoverageTimestamp,
    to: endCoverageTimestamp
  }
}

export const currentTimeExtract = (coverageDate) => {
  
  let startTimestamp =  momenttz.tz(moment(new Date()).format('YYYY-MM-DD HH:mm:ss')).valueOf()

  let coverageTimestampMidnight = moment(`${coverageDate} 00:01:00`).tz('America/New_York').valueOf(); // '2014-08-18T01:00:00+01:00'

  let endTimestamp = momenttz.tz(moment(coverageTimestampMidnight).add(1, 'Y').format('YYYY-MM-DD HH:mm:ss'), 'America/New_York').valueOf()

  return {
    from: startTimestamp,
    to: endTimestamp
  }
}