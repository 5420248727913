import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createPolicy,
  quote,
  quotes,
  selectQuote
} from '../../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
const authState = {
  loading: false,
  error: '',
  selectedVehicle: [],
  duplicateQuote: [],
  metaData: [],
  updatedExposures: '',
  removedExposures: '',
  selectedPackage: {},
  customizedPerils: [],
  premiumData: '',
  dueAmountBasic: '',
  dueAmountAdvanced: '',
  dueAmountPremium: '',
  dueAmountCustom: '',
  onLoadPackages: [],
  newQuoteCreated: false,
  customizedVehiclePerils: []
}

export const quotepageSlice = createSlice({
  name: 'home',
  initialState: authState,
  reducers: {
    setSelectedVehicle: (state, { payload }) => ({
      ...state,
      selectedVehicle: payload
    }),
    setMetaData: (state, { payload }) => ({
      ...state,
      metaData: payload
    }),
    setOnLoadPackages: (state, { payload }) => ({
      ...state,
      onLoadPackages: payload
    }),
    setPremiumData: (state, { payload }) => ({
      ...state,
      premiumData: payload
    }),
    setDueAmountBasic: (state, { payload }) => ({
      ...state,
      dueAmountBasic: payload
    }),
    setDueAmountAdvanced: (state, { payload }) => ({
      ...state,
      dueAmountAdvanced: payload
    }),
    setDueAmountPremium: (state, { payload }) => ({
      ...state,
      dueAmountPremium: payload
    }),
    setDueAmountCustom: (state, { payload }) => ({
      ...state,
      dueAmountCustom: payload
    }),
    setCustomizedPerils: (state, { payload }) => ({
      ...state,
      customizedPerils: payload
    }),
    setCustomizedVehiclePerils: (state, { payload }) => ({
      ...state,

      customizedVehiclePerils: payload
    }),
    setDuplicateQuote: (state, { payload }) => ({
      ...state,
      duplicateQuote: payload
    }),

    setExposures: (state, action) => ({
      ...state,
      updatedExposures: action.payload
    }),
    setRemovedExposures: (state, action) => ({
      ...state,
      removedExposures: action.payload
    }),
    setPackageDetails: (state, action) => ({
      ...state,
      selectedPackage: action.payload
    }),
    setNewQuoteCreated: (state, action) => ({
      ...state,
      newQuoteCreated: action.payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(createDuplicateQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(createDuplicateQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(createDuplicateQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })

    builder.addCase(quoteUpdatePolicy.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(quoteUpdatePolicy.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(quoteUpdatePolicy.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })

    builder.addCase(fetchPricePolicy.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(fetchPricePolicy.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(fetchPricePolicy.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })

    builder.addCase(invalidateQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(invalidateQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(invalidateQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
  }
})

export const createDuplicateQuote = createAsyncThunk(
  'createDuplicateQuote',
  async (params, thunkAPI) => {
    const quotelocator = params?.quotelocator
    let valid = await fetch('/api/usercontroller/quote/createnew', {
      method: 'POST',
      body: JSON.stringify({ payload: params.body, locator: quotelocator }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const invalidateQuote = createAsyncThunk(
  'invalidateQuote',
  async (params, thunkAPI) => {
    const quotelocator = params?.quotelocator
    let valid = await fetch('/api/usercontroller/quote/invalidate', {
      method: 'POST',
      body: JSON.stringify({ locator: quotelocator }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const fetchPricePolicy = createAsyncThunk(
  'fetchPricePolicyApi',
  async (params, thunkAPI) => {
    const policyLocator = params.body?.policyLocator
    let valid = await fetch('/api/usercontroller/quote/fetchprice', {
      method: 'POST',
      body: JSON.stringify({ locator: policyLocator }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const fetchPerilsExposure = createAsyncThunk(
  'fetchPerilsExposureApi',
  async (params, thunkAPI) => {
    const quotelocator = params?.quotelocator
    let valid = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({ payload: params.body, locator: quotelocator }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const quoteUpdatePolicy = createAsyncThunk(
  'quoteUpdatePolicyApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({ payload: params.body, locator: params.locator }),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)

    return result
  }
)

export const {
  setSelectedVehicle,
  duplicateQuote,
  setExposures,
  setRemovedExposures,
  setPackageDetails,
  setMetaData,
  setCustomizedPerils,
  setNewQuoteCreated,
  setCustomizedVehiclePerils,
  setPremiumData,
  setDueAmountBasic,
  setDueAmountAdvanced,
  setDueAmountPremium,
  setDueAmountCustom,
  setOnLoadPackages
} = quotepageSlice.actions

export const quotepageReducer = quotepageSlice.reducer
