import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { aboutyouReducer } from '@pages/feature/aboutyou/aboutyouSlice'
import { zipvalidationReducer } from '@pages/feature/zipvalidation/zipvalidationSlice'
import { authSocotraReducer } from '@pages/feature/common/auth/authSocotraSlice'
import { operatordetailsReducer } from '@pages/feature/operatordetails/operatordetailsSlice'
import { otherquestionsReducer } from '@pages/feature/otherquestions/otherquestionsSlice'
import { stepperReducer } from '@pages/feature/common/stepper/stepperSlice'
import { startApplicationReducer } from '@pages/feature/startapplication/startapplicationSlice'
import { homeReducer } from '@pages/feature/common/home/homeSlice'
import { saferiderReducer } from '@pages/feature/saferider/saferiderSlice'
import { vehicledetailsReducer } from '@pages/feature/vehicledetails/vehicledetailsSlice'
import { finaldetailsReducer } from '@pages/feature/finaldetails/finaldetailsSlice'
import { auxdataReducer } from '@pages/feature/common/auxdata/auxdataSlice'
import { finalquotesReducer } from './feature/finalquotes/finalquotesSlice'
import { quotepageReducer } from '@pages/feature/common/quotepage/quotepageSlice'
import { paymentReducer } from './feature/payment/payment'
import { dashboardReducer } from './feature/dashboard/dashboardSlice'
import { perilsdataReducer } from './feature/common/perilsdata/perilsdataSlice'

const rootReducer = combineReducers({
  aboutYou: aboutyouReducer,
  zipValidation: zipvalidationReducer,
  authSocotra: authSocotraReducer,
  operatorDetails: operatordetailsReducer,
  otherQuestions: otherquestionsReducer,
  stepper: stepperReducer,
  startApplication: startApplicationReducer,
  homePage: homeReducer,
  safeRider: saferiderReducer,
  vehicleDetails: vehicledetailsReducer,
  finalDetails: finaldetailsReducer,
  auxData: auxdataReducer,
  finalQuotes: finalquotesReducer,
  quotePage: quotepageReducer,
  payment: paymentReducer,
  dashboard: dashboardReducer,
  perilsData: perilsdataReducer
})

function loadFromLocalStorage() {
  try {
    const serialisedState =
      typeof window !== 'undefined' && localStorage.getItem('persistantState')
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

export default configureStore({
  reducer: rootReducer,
  preloadedState: loadFromLocalStorage(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: true
    })
})
