import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { quote, quotes, selectQuote } from '../../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
const authState = {
  year: '',
  make: '',
  model: '',
  loading: false,
  error: '',
  quote: '',
  premiumData: {},
  quickQuoteVehicleInfo: {},
  basicLimitData: {},
  advLimitData: {},
  preLimitData: {},
  policyCoveragesData: {},
  vehicleCoveragesData: {}
}

export const homeSlice = createSlice({
  name: 'home',
  initialState: authState,
  reducers: {
    setYearData: (state, { payload }) => {
      state.year = payload
    },
    setMakeData: (state, { payload }) => {
      state.make = payload
    },
    setModelData: (state, { payload }) => {
      state.model = payload
    },
    setPremiumData: (state, { payload }) => {
      state.premiumData = payload
    },
    setQuickQuoteVehicleInfo: (state, { payload }) => {
      state.quickQuoteVehicleInfo = payload
    },
    setQuoteData: (state, { payload }) => {
      state.quote = payload
    },
    setBasicLimitData: (state, { payload }) => {
      state.basicLimitData = payload
    },
    setAdvLimitData: (state, { payload }) => {
      state.advLimitData = payload
    },
    setPreLimitData: (state, { payload }) => {
      state.preLimitData = payload
    },
    setPolicyCoveragesData: (state, { payload }) => {
      state.policyCoveragesData = payload
    },
    setVehicleCoveragesData: (state, { payload }) => {
      state.vehicleCoveragesData = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(getQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(getQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(getQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(selectQuotes.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(selectQuotes.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(selectQuotes.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })

    builder.addCase(fetchQuotePrice.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(fetchQuotePrice.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(fetchQuotePrice.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.quote = action.payload
    })
  }
})

export const getQuote = createAsyncThunk(
  'getQuoteApi',
  async (params, thunkAPI) => {
    const quotelocator = params.body?.quotelocator
    let valid = await fetch('/api/usercontroller/quote/fetchquote', {
      method: 'POST',
      body: JSON.stringify({ locator: quotelocator }),
      headers: {
        // auth: auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const selectQuotes = createAsyncThunk(
  'selectQuotesApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/quote/selectquote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator, payload: {} }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const fetchQuotePrice = createAsyncThunk(
  'fetchQuotePrice',
  async (params, thunkAPI) => {
    const quotelocator = params.body?.quotelocator
    let valid = await fetch('/api/usercontroller/quote/quoteprice', {
      method: 'POST',
      body: JSON.stringify({ locator: params.body?.quotelocator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const getInsuranceplan = createAsyncThunk(
  'getInsuranceplanByState',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/insuranceplan', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: {},
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)

    return check
  }
)

export const {
  setYearData,
  setMakeData,
  setModelData,
  setPremiumData,
  setQuickQuoteVehicleInfo,
  setQuoteData,
  setBasicLimitData,
  setAdvLimitData,
  setPreLimitData,
  setPolicyCoveragesData,
  setVehicleCoveragesData
} = homeSlice.actions

export const homeReducer = homeSlice.reducer
