export const getAgentFieldValues = (agent: any) => {
  let agentFieldValues: any = {}

  if (agent?.agent_id) {
    agentFieldValues = {
      agent_id: agent?.agent_id || '',
      agent_name: `${agent?.first_name} ${agent?.last_name}` || '',
      agent_email: agent?.email_id || '',
      agent_phone: agent?.prim_phone_no || '',
      agent_type: agent?.agent_type || '',
      agent_address: `${agent?.comp_addr_line1}${`${
        agent?.comp_addr_line2 ? `, ${agent?.comp_addr_line2}` : ''
      }`}`,
      agent_zip: agent?.zip || '',
      agent_city: agent?.city || '',
      agent_state: agent?.state || '',
      business_type: 'agent'
    }
    if (agent?.agent_type === 'Independent Agent') {
      agentFieldValues.commission_new_type = agent?.comm_typ_nb || ''
      agentFieldValues.commission_renew_type = agent?.comm_typ_rb || ''
      agentFieldValues.commission_new = agent?.comm_nb || ''
      agentFieldValues.commission_renew = agent?.comm_rb || ''
    }
  }

  return agentFieldValues
}

export const getAgencyFieldValues = (agency: any) => {
  let agencyFieldValues: any = {}

  if (agency?.agency_id) {
    agencyFieldValues = {
      agency_id: agency.agency_id || '',
      agency_name: agency.agency_name || '',
      agency_phone: agency.prim_cont_no || '',
      commission_new_type: agency.comm_typ_nb || '',
      commission_renew_type: agency.comm_typ_rb || '',
      commission_new: agency.comm_nb || '',
      commission_renew: agency.comm_rb || '',
      agency_code: agency.agency_code || '',
      agency_address: `${agency.addr_line1}${`${
        agency.addr_line2 ? `, ${agency.addr_line2}` : ''
      }`}`,
      agency_zip: agency.zip || '',
      agency_city: agency.city || '',
      agency_state: agency.state || '',
      business_type: 'agent',
      parent_agency_id: agency.parent_agency_id || '',
      parent_agency_code: agency.parent_agency_code || ''
    }
    if (agency.prim_cont_mail) {
      agencyFieldValues.agency_email = agency.prim_cont_mail || ''
    }
  }
  return agencyFieldValues
}
