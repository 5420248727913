import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ffpayment } from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
const authState = {
  isLoading: false,
  isError: '',
  savePageData: '',
  submit: '',
  savePremium: '',
  saveFee: '',
  saveInvoice: '',
  invalidOperatorLicense: [],
  updatedResponse: '',
  price: ''
}

export const finaldetailsSlice = createSlice({
  name: 'finaldetails',
  initialState: authState,

  reducers: {
    setInvoice: (state, { payload }) => ({
      ...state,
      saveInvoice: payload
    }),
    setPrice: (state, { payload }) => ({
      ...state,
      price: payload
    }),
    setSubmit: (state, { payload }) => ({
      ...state,
      submit: payload
    }),
    setPageData: (state, { payload }) => ({
      ...state,
      savePageData: payload
    }),
    setInvalidOperatorLicense: (state, { payload }) => ({
      ...state,
      invalidOperatorLicense: payload
    }),
    setSavePremium: (state, { payload }) => ({
      ...state,
      savePremium: payload
    }),
    setSaveFee: (state, { payload }) => ({
      ...state,
      saveFee: payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(updatedPolicy.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updatedPolicy.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(updatedPolicy.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.updatedResponse = action.payload.characteristics[0]
    })
    builder.addCase(lexisNexis.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(lexisNexis.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(lexisNexis.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.updatedResponse = action.payload.characteristics[0]
    })
    builder.addCase(ffPayment.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(ffPayment.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(ffPayment.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
    })
    builder.addCase(ffPayPayment.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(ffPayPayment.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(ffPayPayment.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
    })
    builder.addCase(lexisNexisEndorsement.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(lexisNexisEndorsement.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(lexisNexisEndorsement.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.updatedResponse = action.payload
    })
  }
})

export const updatedPolicy = createAsyncThunk(
  'updatedPolicyApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/updatepolicy',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body, locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const lexisNexis = createAsyncThunk(
  'lexisNexisApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/lexisnexis',

      {
        method: 'POST',
        body: JSON.stringify(params.body),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const lexisNexisEndorsement = createAsyncThunk(
  'lexisNexisEndorsementApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/lexisnexisendorsement',

      {
        method: 'POST',
        body: JSON.stringify(params.body),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const ffPayment = createAsyncThunk(
  'ffPaymentApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/functionalfinance/ffcreate',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)
export const ffPayPayment = createAsyncThunk(
  'ffPayPaymentApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/functionalfinance/ffpayment',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)

      .then((error) => error)
    return check
  }
)

export const {
  setSubmit,
  setPageData,
  setSaveFee,
  setSavePremium,
  setInvalidOperatorLicense,
  setInvoice,
  setPrice
} = finaldetailsSlice.actions

export const finaldetailsReducer = finaldetailsSlice.reducer
