import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { quotes } from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
const authState = {
  isLoading: false,
  isError: '',
  saveSubmitData: '',
  updatedQuestions: ''
}

export const otherquestionsSlice = createSlice({
  name: 'otherquestions',
  initialState: authState,
  reducers: {
    onSubmit: (state, action) => ({
      ...state,
      saveSubmitData: action.payload
    }),
    setData: (state, action) => ({
      ...state,
      updatedQuestions: action.payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(updateQuestions.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateQuestions.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(updateQuestions.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.updatedQuestions = {
        insurance_score:
          action.payload.characteristics.fieldValues.insurance_score,
        felony: action.payload.characteristics.fieldValues.felony,
        insurance_fraud_conviction:
          action.payload.characteristics.fieldValues.insurance_fraud_conviction,
        insurance_fraud_conviction_insured:
          action.payload.characteristics.fieldValues
            .insurance_fraud_conviction_insured,
        any_prior_insurance_with_any_carrier:
          action.payload.characteristics.fieldValues
            .any_prior_insurance_with_any_carrier,
        prior_ins_cov_end_date:
          action.payload.characteristics.fieldValues.prior_ins_cov_end_date,
        returning_from_oversees_duty:
          action.payload.characteristics.fieldValues
            .returning_from_oversees_duty,
      };
    });
  },
});

export const updateQuestions = createAsyncThunk(
  'updateQuestionsApi',
  async (params, thunkAPI) => {
    let check = await fetch('/api/usercontroller/quote/updatequote', {
      method: 'POST',
      body: JSON.stringify({ payload: params.body, locator: params.locator }),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)

    return check
  }
)

export const { onSubmit, setData } = otherquestionsSlice.actions

export const otherquestionsReducer = otherquestionsSlice.reducer
