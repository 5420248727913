import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { quotes, auxDataUrl } from '../../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'

const authState = {
  auxData: '',
  loading: false,
  error: ''
}

export const auxdataSlice = createSlice({
  name: 'auxdata',
  initialState: authState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(setAuxData.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(setAuxData.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(setAuxData.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(getAuxData.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(getAuxData.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(getAuxData.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.auxData = action.payload
    })
    builder.addCase(getAuxDataDashboard.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(getAuxDataDashboard.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(getAuxDataDashboard.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.auxData = action.payload
    })
    builder.addCase(setAuxDataDashboard.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(setAuxDataDashboard.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(setAuxDataDashboard.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
  }
})

export const setAuxDataDashboard = createAsyncThunk(
  'setAuxDataDashboardApi',
  async (params, thunkAPI) => {
    const policyLocator = params?.policyLocator
    let valid = await fetch('/api/usercontroller/session/getdetails', {
      method: 'POST',
      body: JSON.stringify({ finalbody: params.body, locator: policyLocator }),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const getAuxDataDashboard = createAsyncThunk(
  'getAuxDataDashboardApi',
  async (params, thunkAPI) => {
    const policyLocator = params?.policyLocator
    let valid = await fetch('/api/usercontroller/session/get', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((error) => error)
    return valid
  }
)

export const setAuxData = createAsyncThunk(
  'setAuxDataApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/session/set', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const getAuxData = createAsyncThunk(
  'getAuxDataApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/session/get', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

export const getAuxDataIssued = createAsyncThunk(
  'getAuxDataIssuedApi',
  async (params, thunkAPI) => {
    let valid = await fetch('/api/usercontroller/session/get', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // auth: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return valid
  }
)

// export const {} = auxdataSlice.actions

export const auxdataReducer = auxdataSlice.reducer
