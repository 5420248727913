import { resetVersion } from '@pages/feature/startapplication/startapplicationSlice'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { revertAll } from '@pages/feature/common/commonAction'
import moment from 'moment'
import momenttz from 'moment-timezone'
import _ from 'lodash';
import {
  createPolicy,
  createPolicyHolder,
  policies,
  policy,
  quotes
} from '../../../constants/apiconstants'
import { getAgencyFieldValues, getAgentFieldValues } from '@pages/helpers'
import { estTimeExtract } from 'pages/feature/common/utilities'


// create a slice function with name, initial State and reducer function.

const authState = {
  policyHolderLocator: '',
  policyLocator: '',
  quoteLocator: '',
  state: '',
  zipCode: '',
  loading: '',
  error: '',
  loading1: '',
  error1: ''
}

export const zipvalidationSlice = createSlice({
  name: 'zipValidation',
  initialState: authState,
  reducers: {
    setPolicyHolder: (state, { payload }) => {
      state.policyHolderLocator = payload
    },
    setPolicyLocator: (state, { payload }) => {
      state.policyLocator = payload
    },
    setQuoteLocator: (state, { payload }) => {
      state.quoteLocator = payload
    },
    setZipCode: (state, { payload }) => {
      state.zipCode = payload
    },
    setStateValue: (state, { payload }) => {
      state.state = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(policyCreation.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(policyCreation.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(policyCreation.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(fetchQuote.pending, (state, action) => {
      state.loading1 = 'Pending'
    })
    builder.addCase(fetchQuote.rejected, (state, action) => {
      state.loading1 = 'Rejected'
      state.error1 = action.error.message
    })
    builder.addCase(fetchQuote.fulfilled, (state, action) => {
      state.loading1 = 'Complete'
      state.policyHolderLocator = action.payload.policyholderLocator
      state.policyLocator = action.payload.policyLocator
      state.quoteLocator = action.payload.locator
      state.zipCode = action.payload.characteristics.fieldValues.zipcode[0]
    })
  }
})

export const policyCreation = createAsyncThunk(
  'TestPolicyApi',

  async (params, thunkAPI) => {
    let response = ''
    const body = {
      values: {
        last_name: 'Test',
        first_name: 'Test',
        middle_initial: 'Test',
        suffix: 'Jr.'
      }
    }
    await fetch('/api/usercontroller/quote/createpolicyholder', {
      method: 'POST',
      body: JSON.stringify(body),

      headers: {
        // auth: params.auth.authorizationToken,

        'Content-Type': 'application/json',

        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((res) => res.json())
      .then(async (data) => {
        const policyholderlocator = data.locator
        thunkAPI.dispatch(setPolicyHolder(policyholderlocator))

        if (
          authState.policyHolderLocator == '' ||
          authState.policyHolderLocator != policyholderlocator
        ) {
          thunkAPI.dispatch(resetVersion(1))
        }

        const agentData = JSON.parse(localStorage.getItem('agent') || '{}');
        let agent = {};
        let agency = {};
        let utm_tracking_string = localStorage.getItem('utm_tracking_string') || '';

        if (agentData?.agent) {
          agent = getAgentFieldValues(agentData?.agent)
          agency = getAgencyFieldValues(agentData?.agency)
          if (agentData?.utm_tracking_string) {
            const parsedQueryString = JSON.parse(utm_tracking_string || '{}');
            utm_tracking_string = JSON.stringify({
              ...parsedQueryString,
              ...agentData?.utm_tracking_string
            })
          }
        }
        
        const currentDate = momenttz.tz(new Date(), 'America/New_York')
        const afterAnYearDate = moment(currentDate).add(1, 'years')
        const startDate = Math.floor(moment(currentDate))
        const endDate = Math.floor(moment(afterAnYearDate))
        let FormatToday = momenttz.tz('America/New_York').format('YYYY-MM-DD')
        const policyDate = estTimeExtract(FormatToday)

        const policy = {
          finalize: 'false',
          policyholderLocator: policyholderlocator,
          productName: 'Boundless Rider Motorcycle and Motorsports Program',
          policyStartTimestamp: policyDate.from,
          policyEndTimestamp: policyDate.to,
          paymentScheduleName: 'monthly',
          fieldValues: {
            quote_type: 'Detailed Quote',
            coverage_start: FormatToday,
            zipcode: params.zipcode,
            coverage_start_dt: policyDate.from,
            user_id: params.user_id,
            ...agent,
            ...agency,
          }
        }
        if (utm_tracking_string?.length) {
          policy.fieldValues.utm_tracking_string = utm_tracking_string
        }
        await fetch('/api/usercontroller/quote/createpolicy', {
          method: 'POST',
          body: JSON.stringify(policy),

          headers: {
            // auth: params.auth.authorizationToken,

            'Content-Type': 'application/json',

            Accept: 'application/json',
            'Accept-Encoding':
              'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
          }
        })
          .then((res1) => res1.json())
          .then(async (data1) => {
            const policylocator = data1.locator
            thunkAPI.dispatch(setPolicyLocator(policylocator))
            await fetch('/api/usercontroller/quote/fetchallquotes', {
              method: 'POST',
              body: JSON.stringify(policylocator),
              headers: {
                // auth: params.auth.authorizationToken,

                'Content-Type': 'application/json',

                Accept: 'application/json',
                'Accept-Encoding':
                  'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
              }
            })
              .then((res2) => res2.json())
              .then((data2) => {
                response = data2[0].locator
                thunkAPI.dispatch(setQuoteLocator(data2[0].locator))
              })
              .catch((error) => {})
          })

          .catch((error) => {})
      })

      .catch((error) => {})
    return response
  }
)

export const fetchQuote = createAsyncThunk(
  'fetchQuoteApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/quote/fetchquote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.quoteLocator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)

    return check
  }
)

export const {
  setPolicyHolder,
  setPolicyLocator,
  setQuoteLocator,
  setZipCode,
  setStateValue
} = zipvalidationSlice.actions

export const zipvalidationReducer = zipvalidationSlice.reducer
