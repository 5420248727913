import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createPolicy,
  quote,
  quotes,
  selectQuote,
  policy,
  issue,
  accept
} from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'

const authState = {
  loading: false,
  error: '',
  data: ''
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: authState,
  reducers: {
    //     setEditModeFinalQuote: (state, { payload }) => ({
    //     ...state,
    //     editMode: payload,
    //   }),
    reset: (state, { payload }) => ({
      ...state,
      editModeFinalQuote: false,
      loading: false,
      error: '',
      data: ''
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(updateQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(updateQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(updateQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(issuePolicy.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(issuePolicy.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(issuePolicy.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
  }
})

export const updateQuote = createAsyncThunk(
  'updateQuoteApi1',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/updatequote',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body, locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return check
  }
)
export const issuePolicy = createAsyncThunk(
  'issuePolicyApi',
  async (params, thunkAPI) => {
    let check = fetch(
      Url + policy + params.locator + issue,

      {
        method: 'POST',
        body: JSON.stringify(params.body),
        headers: {
          // Authorization: params.auth?.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)
    return check
  }
)

export const { reset } = paymentSlice.actions

export const paymentReducer = paymentSlice.reducer
