import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Container } from '../components/layout'
import { Navbar } from '../components/navbar'
import '../styles/tailwind.css'
import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { StrictMode } from 'react'
import { SessionProvider } from 'next-auth/react'
import SEO from './next-seo-config'
import store from './store'
import { Provider } from 'react-redux'
import '../styles/tailwind.css'
import '../styles/globals.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import { Footer } from '@components/footer/index.page'

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  function saveToLocalStorage(state: any) {
    try {
      const serialisedState = JSON.stringify(state)
      localStorage.setItem('persistantState', serialisedState)
    } catch (e) {
      console.warn(e)
    }
  }
  store.subscribe(() => saveToLocalStorage(store.getState()))
  const navbarHeight = '64px'
  const footerHeight = '64px'
  const [showChild, setShowChild] = useState(false)
  const handlebeforeUnload = (e: any) => {}
  useEffect(() => {
    setShowChild(true)
    const email = localStorage.getItem('email')
    const firstName = localStorage.getItem('firstName') || 'Unknown'
    const lastName = localStorage.getItem('lastName') || 'Unknown'
    if (email) {
      Sentry.setUser({ email, firstName, lastName })
    }
  }, [])

  if (!showChild) {
    return null
  }

  if (typeof window === 'undefined') {
    return <></>
  } else {
    return (
      <SessionProvider session={pageProps.session} refetchInterval={0}>
        <Provider store={store}>
          <StrictMode>
            <Head>
              <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
              <meta
                content="width=device-width, initial-scale=1"
                name="viewport"
              />
            </Head>
            <DefaultSeo {...SEO} />
            <div className="relative bg-white">
              <Navbar />
              <Container
                className="relative max-w-screen"
                style={{
                  minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`
                }}>
                <Component {...pageProps} />
              </Container>
              {window.location.pathname == '/home/ziperror' ? null : (
                <Footer>
                  <div></div>
                </Footer>
              )}
            </div>
          </StrictMode>
        </Provider>
      </SessionProvider>
    )
  }
}

export default App
