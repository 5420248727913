import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { revertAll } from '@pages/feature/common/commonAction'
const authState = {
  editMode: false,
  loading: false,
  acceptedState: false,
  error: '',
  data: ''
}

export const finalquotesSlice = createSlice({
  name: 'finalquotes',
  initialState: authState,
  reducers: {
    setEditModeFinalQuote: (state, { payload }) => ({
      ...state,
      editMode: payload
    }),
    setAcceptedState: (state, { payload }) => ({
      ...state,
      editMode: payload
    }),
    reset: (state, { payload }) => ({
      ...state,
      editModeFinalQuote: false,
      loading: false,
      error: '',
      policyData: ''
    })
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(pricingQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(pricingQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(pricingQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.policyData = action.payload
    })
    builder.addCase(selectingQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(selectingQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(selectingQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
      state.policyData = action.payload
    })
    builder.addCase(updatePolicy.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(updatePolicy.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(updatePolicy.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(acceptingQuote.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(acceptingQuote.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(acceptingQuote.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
    builder.addCase(endorsementFinal.pending, (state, action) => {
      state.loading = 'Pending'
    })
    builder.addCase(endorsementFinal.rejected, (state, action) => {
      state.loading = 'Rejected'
      state.error = action.error.message
    })
    builder.addCase(endorsementFinal.fulfilled, (state, action) => {
      state.loading = 'Complete'
    })
  }
})

export const updatePolicy = createAsyncThunk(
  'updatePolicy',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/updatepolicy',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body, locator: params.locator }),
        headers: {
          // auth: params.auth.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })
    return check
  }
)

export const updateQuote = createAsyncThunk(
  'updateQuoteApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/quote/updatequote',

      {
        method: 'POST',
        body: JSON.stringify({ payload: params.body, locator: params.locator }),
        headers: {
          // Authorization: params.auth?.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })
    return check
  }
)

export const pricingQuote = createAsyncThunk(
  'pricingQuoteApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/quoteprice', {
      method: 'POST',
      body: JSON.stringify({
        payload: params.body,
        locator: params.locator
      }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)
export const acceptingQuote = createAsyncThunk(
  'acceptingQuoteApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/acceptquote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)

export const selectingQuote = createAsyncThunk(
  'selectingQuoteApi',
  async (params, thunkAPI) => {
    let result = await fetch('/api/usercontroller/quote/selectquote', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        // auth: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)

export const fetchEndorsement = createAsyncThunk(
  'fetchEndorsementApi',
  async (params, thunkAPI) => {
    let result = await fetch(
      '/api/usercontroller/endorsement/fetchendorsement',
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
        return error;
      })

    return result
  }
)

export const endorsementRemove = createAsyncThunk(
  'endorsementRemoveApi',
  async (params, thunkAPI) => {
    if (params.action == 'discard') {
      let result = await fetch(
        '/api/usercontroller/endorsement/discardendorsement',
        {
          method: 'POST',
          body: JSON.stringify(params),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Encoding':
              'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
          }
        }
      )
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          return error;
        })

      return result
    } else if (params.action == 'invalidate') {
      let result = await fetch(
        '/api/usercontroller/endorsement/invalidateendorsement',
        {
          method: 'POST',
          body: JSON.stringify(params),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Encoding':
              'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
          }
        }
      )
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          return error;
        })
      return result
    }
  }
)
export const endorsementFinal = createAsyncThunk(
  'endorsementFinalApi',
  async (params, thunkAPI) => {
    let check = fetch(
      '/api/usercontroller/endorsement/endorsementcomplete',

      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          // Authorization: params.auth?.authorizationToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return error;
      })
    return check
  }
)

export const { setEditModeFinalQuote, reset, setAcceptedState } =
  finalquotesSlice.actions

export const finalquotesReducer = finalquotesSlice.reducer
