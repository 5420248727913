import { ROUTE_PATHS } from '../../constants'
import { Card } from 'primereact/card'
import Divider from '@mui/material/Divider'

export const Footer = ({ children, className = '', ...rest }) => (
  <>
    <footer
      className={`relative w-full inset-x-0 bottom-0 h-16 ${className}`}
      {...rest}>
      {typeof window !== 'undefined' &&
      (window.location.pathname == '/dashboard' ||
        window.location.pathname == '/viewpolicy' ||
        window.location.pathname == '/viewprofile' ||
        window.location.pathname == '/home/ziperror' ||
        window.location.pathname == '/claims/allclaims' ||
        window.location.pathname == '/claims/viewclaims') ? (
        <div className={`flex bottom-0 asphalt-gray ${className}`}>
          <div className="flex sm:flex-row flex-column asphalt-gray column-gap-6 asphalt-gray left-padding footer-divider py-3">
            <div className="flex flex-column w-24rem row-gap-2">
              <div
                onClick={() =>
                  window.open(ROUTE_PATHS.BOUNDLESSRIDER_FAQ, '_blank')
                }>
                {' '}
                <span className="mt-2 text-md font-Sans text-white font-bold underline cursor-pointer">
                  FAQs
                </span>
              </div>
              <div>
                <span className="mt-2 text-sm underline font-Sans text-white">
                  Will my quote be accurate?
                </span>
              </div>
              <div>
                <span className="mt-2 text-sm underline font-Sans text-white">
                  {' '}
                  Why do you require this information for my quote?{' '}
                </span>
              </div>
              <div>
                <span className="mt-2 text-sm underline font-Sans text-white">
                  Will you check my credit history?
                </span>
              </div>
              <div className="flex flex-row mt-2">
                <div>
                  <span className=" text-sm font-semibold font-Sans text-white">
                    View all questions
                  </span>
                </div>
              </div>
            </div>
            <div className="hidden sm:block">
              <Divider orientation="vertical" />
            </div>
            <div className="flex flex-column row-gap-8">
              <div className="w-25rem flex sm:flex-row flex-column row-gap-2 justify-content-between">
                <div>
                  <p className="text-sm text-white font-Sans font-bold">
                    9 am to 5 pm
                  </p>
                  <span className="text-sm text-white font-Sans font-normal">
                    Working hour
                  </span>
                </div>
                <div>
                  <p className="text-sm text-white font-Sans font-bold">
                    contactus@boundlessrider.com
                  </p>
                  <span className="text-sm text-white font-Sans font-normal">
                    Mail us
                  </span>
                </div>
              </div>
              <div className="flex flex-row column-gap-3 font-normal text-white text-sm font-Sans">
                <p>
                  © 2022-{new Date().getFullYear()} MileUp, Inc. All Rights
                  Reserved.
                </p>
                <div>
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        ROUTE_PATHS.BOUNDLESS_PRIVACY_POLICY,
                        '_blank'
                      )
                    }>
                    Privacy Policy
                  </span>
                  <div className="text-center px-3 text-white-500 py-1 ">
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        window.open(ROUTE_PATHS.CUSTOMER_PORTAL_TERMS, '_blank')
                      }>
                      Customer Portal Terms
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`bottom-0 asphalt-gray flex sm:flex-row sm:py-3 sm:px-5 column-gap-5 flex-column-reverse ${className}`}>
          <div className="text-center py-1">
            <span className="pl-4 text-sm text-white font-normal">
              © 2022-{new Date().getFullYear()} MileUp, Inc. All Rights
              Reserved.
            </span>
          </div>
          <div className="text-center px-3 text-white-500 py-1 ">
            <span
              onClick={() =>
                window.open(ROUTE_PATHS.BOUNDLESS_PRIVACY_POLICY, '_blank')
              }
              className="text-sm cursor-pointer text-white font-semibold sm:font-normal">
              Privacy Policy
            </span>
          </div>
          <div className="text-center px-3 text-white-500 py-1 ">
            <span
              onClick={() =>
                window.open(ROUTE_PATHS.CUSTOMER_PORTAL_TERMS, '_blank')
              }
              className="text-sm cursor-pointer text-white font-semibold sm:font-normal">
              Customer Portal Terms
            </span>
          </div>
        </div>
      )}

      {children}
    </footer>
  </>
)
