import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { auxDataUrl } from '../../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
// import produce from "immer";

const authState = {
  isLoading: false,
  isError: '',
  pageStatus: [],
  completedTill: ''
}

let pageName = [
  'Start Quote',
  'Vehicle Details',
  'About You',
  'Operator Details',
  'Other Questions',
  'Quote'
]

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState: authState,
  reducers: {
    updateStatus: (state, { payload }) => {
      // state.pageStatus = produce(state.pageStatus, (draftState) => {
      //   draftState[payload.index] = payload.status;
      // });
      state.pageStatus = payload.status
      state.completedTill = payload.page
    },
    reset: (state) => ({
      ...state,
      isLoading: false,
      isError: '',
      pageStatus: [],
      currentPage: ''
    })
  },

  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(fetchPageInfo.pending, (state, action) => {
      state.isLoading = 'Pending'
    })
    builder.addCase(fetchPageInfo.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })

    builder.addCase(fetchPageInfo.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.pageStatus = action.payload
    })
  }
})

export const fetchPageInfo = createAsyncThunk(
  'updatePageStatusApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/session/get', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data.value.split(','))
      .then((error) => error)

    return check
  }
)

export const setPageInfo = createAsyncThunk(
  'setPageStatusApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/session/set', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        // Authorization: params.auth.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .then((error) => error)

    return check
  }
)

export const { updateStatus } = stepperSlice.actions
export const stepperReducer = stepperSlice.reducer
