import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Url, PolicyHolderUpdate } from '../../../constants/apiconstants'
import { revertAll } from '@pages/feature/common/commonAction'
import moment from 'moment'
import produce from 'immer'

const authState = {
  version: 1,
  postedData: '',
  coverageDate: '',
  isLoading: false,
  flag: false,
  isError: '',
  updatedPolicyHolderData: '',
  policyHolderLocator: ''
}

export const startApplicationSlice = createSlice({
  name: 'startApplication',
  initialState: authState,
  reducers: {
    incrementVersion: (state, { payload }) => {
      state.version = payload
    },
    setCoverage: (state, { payload }) => {
      state.coverageDate = payload
    },
    setPolicyHolderData: (state, { payload }) => {
      state.updatedPolicyHolderData = payload
    },
    onSubmit: (state, { payload }) => ({
      ...state,
      postedData: payload
    }),
    setFlag: (state, { payload }) => {
      state.flag = payload
    },
    resetVersion: (state, { payload }) => {
      state.version = parseInt(payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(revertAll, (state, action) => authState)
    builder.addCase(updatePolicyHolder.pending, (state, action) => {
      state.isLoading = 'Pending'
    })
    builder.addCase(updatePolicyHolder.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(updatePolicyHolder.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.updatedPolicyHolderData = action.payload.entity?.values
      state.policyHolderLocator = action.payload.locator
      state.version = action.payload.version
    })
    builder.addCase(getPolicyHolder.pending, (state, action) => {
      state.isLoading = 'Pending'
    })
    builder.addCase(getPolicyHolder.rejected, (state, action) => {
      state.isLoading = 'Rejected'
      state.isError = action.error.message
    })
    builder.addCase(getPolicyHolder.fulfilled, (state, action) => {
      state.isLoading = 'Complete'
      state.version = parseInt(action.payload.version)
      state.policyHolderLocator = action.payload.locator
      state.updatedPolicyHolderData = action.payload.entity.values
      state.coverageDate = moment().format('YYYY-MM-DD')
    })
  }
})

export const updatePolicyHolder = createAsyncThunk(
  'updatetPolicyHoderApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/quote/updatepolicyholder', {
      method: 'POST',
      body: JSON.stringify(params.body),
      headers: {
        // auth: params.auth.authorizationToken,

        'Content-Type': 'application/json',

        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error)
    return check
  }
)

export const getPolicyHolder = createAsyncThunk(
  'getPolicyHoderApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/quote/fetchpolicyholder', {
      method: 'POST',
      body: JSON.stringify({ locator: params.locator }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error)
    return check
  }
)

export const updateHubspotContact = createAsyncThunk(
  'updateHubspotContactApi',
  async (params, thunkAPI) => {
    let check = fetch('/api/usercontroller/updatehubspotcontact', {
      method: 'POST',
      body: JSON.stringify( params ),
      headers: {
        'Content-Type': 'application/json',

        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error)
    return check
  }
)

export const {
  incrementVersion,
  setCoverage,
  onSubmit,
  resetVersion,
  setFlag,
  setPolicyHolderData
} = startApplicationSlice.actions

export const startApplicationReducer = startApplicationSlice.reducer
